/*
 * @Author: 蒋文斌
 * @Date: 2021-01-11 16:23:27
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-20 15:36:26
 * @Description: 租户状态
 */
import { merge } from "lodash-es";
import { ActionContext, Module } from "vuex";
import storage from "@/utils/storage";
import { DEFAULT_CENTER_POSITION, DEFAULT_CUSTOMIZED_INFO } from "@/utils/const";
import { isObject } from "@/utils/type";
import { LAYOUT_TEMPLATES } from "@/utils/templates";
import { companyService } from "@/services/admin/company";
import {
    SET_CUSTOMIZED_INFO,
    FETCH_CUSTOMIZED_INFO,
    FETCH_AUTHORIZED_CUSTOMIZED_INFO,
    FETCH_UNAUTHORIZED_CUSTOMIZED_INFO,
} from "@/store/constants";
import { CustomizedConfig, CustomizedInfoDTO } from "@/bean/dto";
import { RenderOptions } from "@/bean/base";
// import { initTheme } from "@/utils/theme";
import { RootState } from "../index";

let customizedInfo = DEFAULT_CUSTOMIZED_INFO;

const customizedInfoInStorage = storage.get("customizedInfo", true);

if (customizedInfoInStorage) {
    customizedInfo = JSON.parse(customizedInfoInStorage);
}

export interface CustomerState {
    customizedInfo: CustomizedInfoDTO;
}

export const customer: Module<CustomerState, RootState> = {
    namespaced: true,
    state: {
        customizedInfo,
    },
    getters: {
        lngLat(state) {
            const { comLon, comLat } = state.customizedInfo;
            if (comLon && comLat) {
                return {
                    lng: comLon,
                    lat: comLat,
                };
            } else {
                return DEFAULT_CENTER_POSITION;
            }
        },
    },
    mutations: {
        [SET_CUSTOMIZED_INFO](state: CustomerState, val: CustomizedInfoDTO | null): void {
            if (val) {
                state.customizedInfo = val;
                storage.set("customizedInfo", JSON.stringify(val), true);
            } else {
                state.customizedInfo = DEFAULT_CUSTOMIZED_INFO;
                storage.remove("customizedInfo", true);
            }
            // initTheme();
        },
    },
    actions: {
        // 获取客户个性化信息，支持切换租户场景时通过传入的comId来获取
        [FETCH_CUSTOMIZED_INFO](
            { rootState, commit, dispatch }: ActionContext<CustomerState, RootState>,
            comId: number
        ): Promise<CustomizedInfoDTO> {
            return new Promise((resolve, reject) => {
                const isAuthorized = isObject(rootState.auth.accessInfo);
                // 已登录和未登录调不同的接口，处理数据时，主要是图片的处理逻辑有差异
                dispatch(isAuthorized ? FETCH_AUTHORIZED_CUSTOMIZED_INFO : FETCH_UNAUTHORIZED_CUSTOMIZED_INFO, comId)
                    .then((customerInfo: CustomizedInfoDTO) => {
                        if (customerInfo) {
                            const integratedCustomerInfo = merge({}, DEFAULT_CUSTOMIZED_INFO, customerInfo);
                            if (integratedCustomerInfo.sysName) {
                                integratedCustomerInfo.platformChineseName = integratedCustomerInfo.sysName;
                            }
                            if (integratedCustomerInfo.sysKeywords) {
                                integratedCustomerInfo.platformEnglishName = integratedCustomerInfo.sysKeywords;
                            }
                            if (integratedCustomerInfo.sysFpKeywords) {
                                integratedCustomerInfo.shortName = integratedCustomerInfo.sysFpKeywords;
                            }
                            // 取customizeConfig作为扩展json字段
                            if (integratedCustomerInfo.customizeConfig) {
                                try {
                                    const customizeConfigObj = JSON.parse(integratedCustomerInfo.customizeConfig);
                                    if (isObject<CustomizedConfig>(customizeConfigObj)) {
                                        if (customizeConfigObj.useTemplate) {
                                            const template = LAYOUT_TEMPLATES.find(
                                                (item) => item.code === customizeConfigObj.layoutTemplateCode
                                            );
                                            if (template) {
                                                customizeConfigObj.defaultLayout = template.option;
                                            }
                                        } else if (customizeConfigObj.defaultLayout) {
                                            if ((customizeConfigObj.defaultLayout as string)[0] === "{") {
                                                try {
                                                    const parsedLayoutOption = JSON.parse(customizeConfigObj.defaultLayout as string);
                                                    customizeConfigObj.defaultLayout = isObject<RenderOptions>(parsedLayoutOption)
                                                        ? parsedLayoutOption
                                                        : "";
                                                } catch (err) {
                                                    console.error(err);
                                                    customizeConfigObj.defaultLayout = "";
                                                }
                                            }
                                        }
                                        integratedCustomerInfo.config = merge({}, DEFAULT_CUSTOMIZED_INFO.config, customizeConfigObj);
                                    }
                                } catch (error) {
                                    console.error("客户个性化配置数据格式有误");
                                } finally {
                                    commit(SET_CUSTOMIZED_INFO, integratedCustomerInfo);
                                    resolve(integratedCustomerInfo);
                                }
                            } else {
                                integratedCustomerInfo.config = DEFAULT_CUSTOMIZED_INFO.config;
                                commit(SET_CUSTOMIZED_INFO, integratedCustomerInfo);
                                resolve(integratedCustomerInfo);
                            }

                            // 处理完之后，根据 config 的值进行特殊操作
                            if (integratedCustomerInfo.config.favIconUrl) {
                                // 如果配置了 favicon，更新
                                const favIconLink = document.querySelector("head >#favIcon");
                                if (favIconLink) {
                                    favIconLink.setAttribute("href", integratedCustomerInfo.config.favIconUrl);
                                }
                            }
                        } else {
                            commit(SET_CUSTOMIZED_INFO, null);
                            reject(new Error("没有客户信息"));
                        }
                    })
                    .catch((err: Error) => {
                        reject(err);
                    });
            });
        },
        // 已登录
        [FETCH_AUTHORIZED_CUSTOMIZED_INFO](): Promise<CustomizedInfoDTO | null> {
            return new Promise((resolve, reject) => {
                companyService
                    .current()
                    .then((res) => {
                        const customerInfo = res.result as CustomizedInfoDTO;
                        if (customerInfo) {
                            if (customerInfo.attachments && customerInfo.attachments.length > 0) {
                                const logoAttachment = customerInfo.attachments.find((item) => item.attrType === "SYS_ATT_COMPANY_LOGO");
                                const systemPictureAttachment = customerInfo.attachments.find(
                                    (item) => item.attrType === "SYS_ATT_COMPANY_PIC"
                                );
                                if (logoAttachment) {
                                    customerInfo.logo = logoAttachment.fileUrl;
                                }
                                if (systemPictureAttachment) {
                                    customerInfo.systemPicture = systemPictureAttachment.fileUrl;
                                }
                            }
                            resolve(customerInfo);
                        } else {
                            resolve(null);
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        // 未登录
        [FETCH_UNAUTHORIZED_CUSTOMIZED_INFO](): Promise<CustomizedInfoDTO | null> {
            return new Promise((resolve, reject) => {
                companyService
                    .i()
                    .then((res) => {
                        const customerInfo = res.result as CustomizedInfoDTO;
                        if (customerInfo) {
                            if (customerInfo.sysIco) {
                                customerInfo.logo = customerInfo.sysIco;
                            }
                            if (customerInfo.sysImage) {
                                customerInfo.systemPicture = customerInfo.sysImage;
                            }
                            resolve(customerInfo);
                        } else {
                            resolve(null);
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
};
