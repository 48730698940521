/*
 * @Author: 蒋文斌
 * @Date: 2021-04-25 10:48:09
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-04-29 18:31:31
 * @Description: auth模块
 */
import { ApiService } from "@/services/index";
import { auth } from "@/services/config";
import { PlainObject } from "@/bean/base";

const INIT_KEY = "initKey";

class AuthService extends ApiService {
    login(params: PlainObject) {
        return this.$get("login", params, { globalRequest: true });
    }

    getVerifyCode(verifyKey: string) {
        return this.$get("getVerifyCode", { verifyKey: verifyKey || INIT_KEY }, { globalRequest: true });
    }

    logout(refreshToken: string) {
        return this.$post("logout", { refreshToken }, { globalRequest: true });
    }

    changeCom(params: PlainObject) {
        return this.$get("changeCom", params, { globalRequest: true });
    }
}

export const authService = new AuthService(auth, "auth");
