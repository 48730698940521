/*
 * @Author: 蒋文斌
 * @Date: 2021-04-15 16:16:38
 * @LastEditors: 蒋文斌
 * @LastEditTime: 2021-05-18 16:39:29
 * @Description: vuex 入口
 */
import { InjectionKey } from "vue";
import { createStore, Store, StoreOptions } from "vuex";
import { customer, CustomerState } from "./modules/customer";
import { auth, AuthState } from "./modules/auth";
import { tabs, TabsState } from "./modules/tabs";
import { prefer, PreferState } from "./modules/prefer";

export type RootState = {
    auth: AuthState;
    customer: CustomerState;
    tabs: TabsState;
    prefer: PreferState;
};

export const key: InjectionKey<Store<RootState>> = Symbol("key");

const rootStoreOption: StoreOptions<RootState> = {
    modules: {
        auth, // 认证模块
        customer, // 客户模块
        tabs, // tabs模块
        prefer, // 用户偏好模块
    },
};

const store = createStore(rootStoreOption);

export default store;
