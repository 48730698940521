
import { defineComponent } from "vue";

export default defineComponent({
    name: "ClXLayout",
    props: {
        options: {
            type: Object,
            default() {
                return {};
            },
        },
    },
});
