
import { useStore } from "vuex";
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { pick } from "lodash-es";
import { key } from "@/store";
import { FlatMenuItemDTO } from "@/bean/dto";
import { useBreadcrumbList, useRootMenu } from "../helper";

interface MenuState {
    collapsed: boolean;
    openKeys: Array<number>;
    preOpenKeys: Array<number>;
}

const PickMenuKeys = ["id", "icon", "path", "authType", "authRoute", "stateId", "nameCN", "meta"] as const;

type FormattedMenuItem = Pick<FlatMenuItemDTO, typeof PickMenuKeys[number]> & {
    children: FormattedMenuItem[];
};

function menusFormatter(menus: FlatMenuItemDTO[]): FormattedMenuItem[] {
    return menus.map((item) => {
        return {
            ...pick(item, PickMenuKeys),
            children: menusFormatter(item.children || []),
        };
    });
}

export default defineComponent({
    name: "ClBackendLayout",
    props: {
        useSlot: Boolean,
    },
    setup() {
        // vuex
        const store = useStore(key);
        // router
        const route = useRoute();
        const router = useRouter();
        // 权限相关
        const flatMenus = computed(() => store.state.auth.flatMenus);

        const xLayoutOptions = ref({
            containerClass: "backend-container",
            asideClass: "backend-aside",
            mainClass: "backend-main",
        });

        const yLayoutOptions = ref({
            headerClass: "breadcrumb-wrapper",
            mainClass: "padding-0",
        });

        // 面包屑
        const { breadcrumbList } = useBreadcrumbList();

        // 菜单相关
        const { rootMenu } = useRootMenu();

        const navs = computed(() => {
            return rootMenu.value && rootMenu.value.children ? menusFormatter(rootMenu.value.children) : [];
        });

        const initOpenKeys = breadcrumbList.value.map((item) => item.id);
        // 菜单状态项
        const menuState = reactive<MenuState>({
            collapsed: false,
            openKeys: initOpenKeys,
            preOpenKeys: initOpenKeys,
        });
        // 监听openKeys的变化，记录preOpenKeys
        watch(
            () => menuState.openKeys,
            (val, oldVal) => {
                menuState.preOpenKeys = oldVal;
            }
        );
        // 路由的变化会引起面包屑更新，而openKeys恰好和面包屑对应
        watch(breadcrumbList, (val) => {
            menuState.openKeys = val.map((item) => item.id);
        });
        // 选中keys
        const selectedKeys = computed(() => {
            const menu = flatMenus.value.find((item) => item.path === decodeURIComponent(route.path));
            return menu ? [menu.id] : [];
        });

        // 点击菜单
        const onClickMenu = ({ key }: { key: number; keyPath: Array<number> }) => {
            const page = flatMenus.value.find((menu) => menu.id === key);
            if (page) {
                router.push(page.path);
            }
        };

        return {
            xLayoutOptions,
            yLayoutOptions,
            navs,
            breadcrumbList,
            onClickMenu,
            menuState,
            selectedKeys,
        };
    },
});
