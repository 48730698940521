/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */
import axios, { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from "axios";
import qs from "qs";
import { message } from "ant-design-vue";
import { merge } from "lodash-es";
import store from "@/store";
import router from "@/router";
import { AUTH_MODULE, SET_ACCESS_INFO } from "@/store/constants";
import { requestParamsFilter } from "@/utils/helper";
import { PlainObject } from "@/bean/base";
import { AccessInfoDTO } from "@/bean/dto";
import { Response, RecordResponse } from "@/bean/xhr";
import { LOGIN_ROUTE } from "@/router/no-auth";
import { NOT_FOUND_ROUTE } from "@/router/404";
import { isBlob } from "@/utils/type";
import { ShapeFlags } from "@/utils/vue";
import { cancelSourceMap } from "./cancel";

const REFRESH_TOKEN_URL = "/auth-web/auth/refreshToken";
const LOGOUT_URL = "/auth-web/auth/logout";
const GET_MENUS_URL = "/iam/user/getMenus";

// 免认证头 URL
const SKIP_AUTH_URLS = [REFRESH_TOKEN_URL, LOGOUT_URL];

type TriggerFn = (a: AccessInfoDTO) => void;

export enum InnerCode {
    TokenEmpty = 4001,
    TokenInvalid = 4002,
    TokenNeedRefresh = 4003,
    TokenExpired = 1415,
}

const api = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 20000,
});

// axios初始化配置
api.defaults.headers.common["Content-Type"] = "application/x-www-form-urlencoded";
api.defaults.transformRequest = (data) => {
    return qs.stringify(data, { encode: true });
};

type EnhancedRequestConfig = AxiosRequestConfig & { globalRequest?: boolean };

// 请求拦截
api.interceptors.request.use((config: EnhancedRequestConfig) => {
    // cancelToken 处理
    const { path, matched } = router.currentRoute.value;
    if (matched.length > 0) {
        // 保证是从页面组件发出的请求
        if (!cancelSourceMap.has(path)) {
            const source = axios.CancelToken.source();
            cancelSourceMap.set(path, source);
        }
        const comp = matched[0].instances.default;
        if (comp) {
            const { shapeFlag } = comp.$.vnode;
            // eslint-disable-next-line no-bitwise
            if (!config.globalRequest && !(shapeFlag & ShapeFlags.COMPONENT_KEPT_ALIVE)) {
                // 如果不是全局请求，并且不缓存，才带上 cancelToken
                const { token } = cancelSourceMap.get(path);
                if (token.reason && axios.isCancel(token.reason)) {
                    // 如果 token 已经用过了，重新生成
                    const source = axios.CancelToken.source();
                    cancelSourceMap.set(path, source);
                }
                // 从 map 获取最新的 token
                config.cancelToken = cancelSourceMap.get(path).token;
            }
        }
    }

    // 鉴权处理
    const { accessInfo } = store.state[AUTH_MODULE];
    if (accessInfo) {
        const { token_type, access_token } = accessInfo;
        // 添加headers
        if (!SKIP_AUTH_URLS.includes(config.url as string)) {
            (config.headers as AxiosRequestHeaders).Authorization = `${token_type} ${access_token}`;
        }
        if (config.url === GET_MENUS_URL) {
            // 仅查询PC平台权限
            (config.headers as AxiosRequestHeaders).appId = "hq";
        }
    }

    // 链路分析
    if (window._devtoolOpenFlag) {
        (config.headers as AxiosRequestHeaders)["X-B3-Flags"] = "1";
        (config.headers as AxiosRequestHeaders).b3 = "1";
    }
    return config;
});

const codeMessage = {
    [InnerCode.TokenEmpty]: "令牌为空，请重新登录",
    [InnerCode.TokenInvalid]: "令牌无效，请重新登录",
    [InnerCode.TokenNeedRefresh]: "令牌过期，需要重新获取令牌",
    [InnerCode.TokenExpired]: "身份信息过期，请重新登录",
};

function showErrorMsgByResponse(data: Response): void {
    const msg = codeMessage[data.code as InnerCode] || data.message;
    if (msg) {
        message.error(msg);
    }
}

let isTokenRefreshing = false;

let queue: TriggerFn[] = [];

function refreshToken() {
    const { accessInfo } = store.state[AUTH_MODULE];
    if (!accessInfo) {
        // accessInfo数据不存在，回到登录页
        router.push(LOGIN_ROUTE.path);
        return Promise.reject(new Error("refresh_token is missing."));
    }
    const { refresh_token } = accessInfo;
    // 拦截器改变了返回数据结构，这里指定泛型
    return api
        .post<RecordResponse, RecordResponse<AccessInfoDTO>>("/auth-web/auth/refreshToken", {
            refreshToken: refresh_token,
        })
        .then((res) => {
            // 更新认证信息
            const accessInfo = res.result;
            store.commit(`${AUTH_MODULE}/${SET_ACCESS_INFO}`, accessInfo);
            // 执行队列中的请求
            queue.forEach((cb) => {
                cb(accessInfo);
            });
            // 清空队列
            queue = [];
            isTokenRefreshing = false;
            return accessInfo;
        })
        .catch(() => {
            router.push(LOGIN_ROUTE.path);
            return Promise.reject(new Error("refresh token failed."));
        });
}

// 返回状态拦截
api.interceptors.response.use(
    (response) => {
        const { data, config } = response;
        if (isBlob(data)) {
            // 文件流，直接返回
            return Promise.resolve(response);
        } else if (data.success) {
            return Promise.resolve(data);
        } else {
            // inner code handler
            switch (data.code) {
                // 需要重新登录
                case InnerCode.TokenEmpty:
                case InnerCode.TokenInvalid:
                case InnerCode.TokenExpired:
                    const currRoute = router.currentRoute.value;
                    showErrorMsgByResponse(data);
                    // 重定向到login
                    router.push({
                        path: LOGIN_ROUTE.path,
                        query: {
                            // 如果redirect目标是404或者login，就不采用redirect
                            redirect: [LOGIN_ROUTE.path, NOT_FOUND_ROUTE.path].includes(currRoute.path) ? "" : currRoute.fullPath,
                        },
                    });
                    break;
                case InnerCode.TokenNeedRefresh:
                    // 刷新token
                    if (!isTokenRefreshing) {
                        isTokenRefreshing = true;
                        refreshToken();
                    }
                    return new Promise((resolve) => {
                        const trigger: TriggerFn = function ({ token_type, access_token }) {
                            config.transformRequest = (data) => data;
                            (config.headers as AxiosRequestHeaders).Authorization = `${token_type} ${access_token}`;
                            resolve(api.request(config));
                        };
                        queue.push(trigger);
                    });
                default:
                    showErrorMsgByResponse(data);
                    break;
            }
            return Promise.reject(data);
        }
    },
    (error) => {
        if (axios.isCancel(error)) {
            return Promise.reject(error);
        }
        console.error(error.response);
        if (/timeout\sof\s\d+ms\sexceeded/.test(error.message)) {
            // 超时
            message.error("网络请求超时，请检查网络连接！");
        } else if (error.response) {
            switch (error.response.status) {
                // http status handler
                case 400: // 客户端请求有误
                    message.error("400: 客户端请求有误，请联系管理员！");
                    break;
                case 401: // 未授权
                    message.error("401: 未授权访问，请联系管理员！");
                    break;
                case 403: // 禁止访问
                    message.error("403: 服务器拒绝访问该资源，请联系管理员！");
                    break;
                case 404: // 找不到
                    message.error("404: 访问的资源不存在，请稍后重试！");
                    break;
                case 502: // bad gateway
                case 503: // service unavailable
                case 504: // gateway timeout
                    message.error(`${error.response.status}: 网关或代理服务器异常，请稍后重试！`);
                    break;
                case 500: // 服务器内部错误
                default:
                    const errmsg = error.response.data.message;
                    if (errmsg) {
                        message.error(`${error.response.status}: ${errmsg}`);
                    } else {
                        message.error(`${error.response.status}: 系统繁忙，请稍后重试！`);
                    }
                    break;
            }
        }
        return Promise.reject(error.response);
    }
);

export class ApiService {
    // 微服务上下文
    private context: string;

    // 特性
    private feature: string;

    constructor(context: string, feature: string) {
        this.context = context;
        this.feature = feature;
    }

    private getUrl(action: string): string {
        return `/${this.context}/${this.feature}/${action}`;
    }

    // get请求
    protected $get<T extends Response>(action: string, params: PlainObject = {}, config: PlainObject = {}): Promise<T> {
        return api.get(this.getUrl(action), {
            ...config,
            params: requestParamsFilter(params, true),
        });
    }

    // get下载请求
    protected $download(action: string, params: PlainObject = {}, config: PlainObject = {}): Promise<AxiosResponse> {
        return api.get(this.getUrl(action), {
            ...config,
            params: requestParamsFilter(params, true),
            responseType: "blob",
            timeout: 100000,
        });
    }

    // delete请求
    protected $del<T extends Response>(action: string, params: PlainObject = {}, config: PlainObject = {}): Promise<T> {
        return api.delete(this.getUrl(action), {
            ...config,
            params: requestParamsFilter(params, true),
        });
    }

    // delete application/json请求
    protected $delJson<T extends Response>(action: string, params: PlainObject | unknown[] = {}, config: PlainObject = {}): Promise<T> {
        const defaultConfig = {
            headers: { "Content-Type": "application/json" },
            transformRequest: (data: PlainObject) => JSON.stringify(data),
        };
        return api.delete(this.getUrl(action), merge({}, defaultConfig, config, { data: requestParamsFilter(params) }));
    }

    // post请求
    protected $post<T extends Response>(action: string, params: PlainObject = {}, config: PlainObject = {}): Promise<T> {
        return api.post(this.getUrl(action), requestParamsFilter(params), config);
    }

    // post application/json请求
    protected $postJson<T extends Response>(action: string, params: PlainObject = {}, config: PlainObject = {}): Promise<T> {
        const defaultConfig = {
            headers: { "Content-Type": "application/json" },
            transformRequest: (data: PlainObject) => JSON.stringify(data),
        };
        return this.$post(action, params, merge({}, defaultConfig, config));
    }

    // post下载请求
    protected $postDownload(action: string, params: PlainObject = {}, config: PlainObject = {}): Promise<AxiosResponse> {
        const defaultConfig: AxiosRequestConfig = {
            responseType: "blob",
            headers: { "Content-Type": "application/json" },
            transformRequest: (data: PlainObject) => JSON.stringify(data),
            timeout: 100000,
        };
        return api.post(this.getUrl(action), requestParamsFilter(params), merge({}, defaultConfig, config));
    }

    // 上传请求，formdata
    protected $upload<T extends Response>(action: string, params: FormData = new FormData(), config: PlainObject = {}): Promise<T> {
        const defaultConfig: PlainObject = {
            headers: { "Content-Type": "multipart/form-data" },
            transformRequest: null,
            timeout: 100000,
        };
        return api.post(this.getUrl(action), params, merge({}, defaultConfig, config));
    }

    // put请求
    protected $put<T extends Response>(action: string, params: PlainObject = {}, config: PlainObject = {}): Promise<T> {
        return api.put(this.getUrl(action), requestParamsFilter(params, true), config);
    }

    // put application/json请求
    protected $putJson<T extends Response>(action: string, params: PlainObject = {}, config: PlainObject = {}): Promise<T> {
        const defaultConfig = {
            headers: { "Content-Type": "application/json" },
            transformRequest: (data: PlainObject) => JSON.stringify(data),
        };
        return api.put(this.getUrl(action), requestParamsFilter(params), merge({}, defaultConfig, config));
    }

    // patch请求
    protected $patch<T extends Response>(action: string, params: PlainObject = {}, config: PlainObject = {}): Promise<T> {
        return api.patch(this.getUrl(action), requestParamsFilter(params, true), config);
    }

    // patch application/json请求
    protected $patchJson<T extends Response>(action: string, params: PlainObject = {}, config: PlainObject = {}): Promise<T> {
        const defaultConfig = {
            headers: { "Content-Type": "application/json" },
            transformRequest: (data: PlainObject) => JSON.stringify(data),
        };
        return api.patch(this.getUrl(action), requestParamsFilter(params), merge({}, defaultConfig, config));
    }
}
